import React from "react";

import Page from "lib/@cms/components/layout/Page";

function AtmLocatorPage() {
  return (
    <Page pathname="atm-locator">
      {() => {
        return (
          <div className="tw-container tw-py-8">
            <iframe
              title="ATM Locator"
              align="middle"
              frameBorder="1"
              height="1000"
              scrolling="yes"
              src="https://co-opcreditunions.org/locator/?ref=allco-op.org&amp;sc=1"
              width="100%"
            />
          </div>
        );
      }}
    </Page>
  );
}

export default AtmLocatorPage;
